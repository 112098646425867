<template>
  <div class="text-center mb-2">
    {{ preLinkText }}
    <span class="link" @click="goToPrivacyPolicyPage" data-cy="privacy-policy-link">
      {{ linkText }}
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
  preLinkText: {
    type: String,
    default: 'بإتمام التبرع أنت توافق على'
  },
  linkText: {
    type: String,
    default: 'سياسات التبرع',
  },
  policyPath: {
    type: String,
    default: '/privacy-policy'
  },
  target: {
    type: String,
    validator: (value) => ['_blank', '_self', '_parent', '_top'].includes(value),
    default: '_blank'
  }
})

const goToPrivacyPolicyPage = () => {
  window.open(process.env.VUE_APP_FE_URL + props.policyPath, props.target);
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.link {
  text-decoration: underline;
  color: $color-primary;
  cursor: pointer;
  transition: $transition-basic;
  &:hover {
    color: $color-primary-hover;
  }
}
</style>