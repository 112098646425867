<template>
  <ShareDialog
    :visible="displayShareDialog"
    :header="header"
    :authenticated="authenticated"
    :sharableLink="sharableLink"
    :facebookLink="facebookLink"
    :twitterLink="twitterLink"
    :whatsappLink="whatsappLink"
    :showSigninInfo="showSigninInfo"
    @close="closeShareDialog"
    @copy="copyLink"
  />
  <span>
    <Button
      v-bind="$attrs"
      @click.stop="openShareDialog"
      class="button-share border-0"
      text
      :class="shareIconClasses"
      data-cy="share-button"
      icon="pi pi-share-alt"
    />
  </span>
</template>

<script setup>
import ShareDialog from "@/components/layout/ShareDialog";
import { ToastSeverity } from "primevue/api";
import { computed, reactive, ref } from "vue";
import { useToast } from "primevue/usetoast";
import { useGenerateShareLinks } from "@/hooks/useGenerateShareLinks";

const props = defineProps({
  header: {
    type: String,
    default: 'ساهم في العلاج عن طريق المشاركة مع الاخرين'
  },
  sharableLink: {
    type: String,
    required: true,
  },
  authenticated: {
    type: Boolean,
    default: false,
  },
  showSigninInfo: {
    type: Boolean,
    default: true,
  },
  variant: {
    type: String,
    default: "primary",
    validation: (val) => [ "pPrimary", "pSecondary", "primary", "secondary", "ongoing", "completed", "cancelled", "legacy"].includes(val),
  },
  toastCopyMessage: {
    type: String,
    default: "تم نسخه بنجاح",
  },
});

const variantClassNames = reactive({
  pPrimary: "",
  pSecondary: "p-button-secondary",
  primary: "share-primary",
  secondary: "share-secondary",
  ongoing: "ongoing-case",
  completed: "completed-case",
  cancelled: "cancelled-case",
  legacy: "legacy-share",
});

const emit = defineEmits(['open, close', 'copy']);

const { facebookLink, twitterLink, whatsappLink } = useGenerateShareLinks(
  props.sharableLink
);

const toast = useToast();
const displayShareDialog = ref(false);

const openShareDialog = () => {
  emit('open');
  displayShareDialog.value = true;
};

const closeShareDialog = () => {
  emit('close');
  displayShareDialog.value = false;
};

const copyLink = () => {
  navigator.clipboard.writeText(props.sharableLink);
  if (props.sharableLink) {
    toast.add({ severity: ToastSeverity.SUCCESS, detail: props.toastCopyMessage, life: 3000 });
  }
  emit('copy');
};

const shareIconClasses = computed(() => {
  return variantClassNames?.[props.variant];
})
</script>

<style scoped lang="scss">
@import "@/styles/variables";
.button-share {
  border-radius: 8px;
  transition: all .2s ease-in-out;
  &.h-100 {
    height: 100% !important;
  }
  .whatsapp-icon,
  .facebook-icon,
  .twitter-icon {
    display: none;
  }
}
.share-primary {
  color: $color-primary;
  background-color: #E9E9E9;
  &:hover {
    background-color: $color-primary;
    color: #FFFFFF;
  }
}
.share-secondary {
  color: #E9E9E9;
  background-color: $color-primary;
  &:hover {
    background-color: $color-primary-border-light;
    color: $color-primary-hover;
  }
}
.ongoing-case {
  @extend .share-secondary;
}
.completed-case {
  color: $color-font-lighter;
  background: lighten($color-secondary-hover, 20%);

  &:hover {
    background: $color-secondary-hover;
  }
}
.legacy-share {
  background-color: $color-gray-lighter !important;
  border-color: $color-gray-lighter !important;
  color: $color-complementary !important;
  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px darken($color-gray-lighter, 18%), 0 1px 2px 0 black !important;
    background-color: darken($color-gray-lighter, 5%) !important;
  }
  &:hover {
    background-color: darken($color-gray-lighter, 5%) !important;
  }
}
</style>
