<template>
  <div v-if="waqfDetails && !closedWaqfCondition" class="donate px-3 px-md-5 mb-5 w-100">
    <div class="donate__container d-flex justify-content-center w-100">
      <DonateCardDetails :payload="waqfDetails" type="waqf"/>
      <div class="donate__card donate__form col-md-6 section bg-white">
        <h2 class="title fw-bold text-center donate__heading">
          المبلغ المراد التبرع به
        </h2>
        <div class="tags d-flex justify-content-evenly mb-5">
          <div :class="{'selected-button ':donationModel.donation_amount===10,'p-disabled': disablePaymentFields }" class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center" @click="setDonationAmount(10)">10 ر.س</div>
          <div :class="{'selected-button ':donationModel.donation_amount===50 ,'p-disabled': disablePaymentFields}" class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center" @click="setDonationAmount(50)">50 ر.س</div>
          <div :class="{'selected-button ':donationModel.donation_amount===100 ,'p-disabled': disablePaymentFields}" class="tag mb-3 mb-lg-0 fw-semibold d-flex align-items-center justify-content-center" @click="setDonationAmount(100)">100 ر.س</div>
          <div :class="{'selected-button ':donationModel.donation_amount=== waqfDetails.target- waqfDetails.balance,'p-disabled': disablePaymentFields}" class="tag fw-semibold d-flex align-items-center justify-content-center" 
          @click="donationModel.donation_amount = waqfDetails.target- waqfDetails.balance;">
          التبرع بالمبلغ
            المتبقي
          </div>
        </div>
        <div class="w-100 mb-1">
          <BaseInputNumber
            v-model="donationModel.donation_amount" 
            :disabled="disablePaymentFields"
            :placeholder="`أدخل مبلغ التبرع`"
            :class="{ 'p-invalid': !donationModel.donation_amount && isSubmitted }"
            id="donation-amount"
            inputId="donation-amount"
            :max="waqfDetails.target - waqfDetails.balance"
            :min="1"

          />
        </div>
        <div class="mb-4 donation-disclaimer">
          أعلى قيمة يمكنك التبرع بها {{waqfDetails.target - waqfDetails.balance}} وأقل قيمة يمكنك التبرع بها 1.00
        </div>

        <BaseInputNumber
          :placeholder="'ليصلك اثر تبرعك أدخل رقم جوالك'"
          v-model="donationModel.doner_mobile" 
          :disabled="disablePaymentFields"
          :class="{'p-invalid': donationModel.doner_mobile && !isValidMobileNumber(donationModel.doner_mobile)}"
          id="doner-mobile"
          :maxLength="10"
          :phoneNumber="true"
          v-if="!$store.getters.isAuthenticated"
        />
        <div class="mb-4">
          <Checkbox
              v-model="donationModel.on_behalf_donation"
              :binary="true"
              inputId="on_behalf_donation"
              id="on_behalf_donation"
          />
          <label for="on_behalf_donation" class="mx-2">تبرع عن من تحب</label>
        </div>
        <div v-if="donationModel.on_behalf_donation">
          <div class="w-100 mb-4">
            <InputText
                inputId="doner-name"
                id="doner-name"
                placeholder="اسم المتبرع"
                v-model="donationModel.on_behalf_donor_name"
                class="w-100"
            />
          </div>
          <div class="w-100 mb-4">
            <BaseInputNumber
              :placeholder="'جوال المهدى له'"
              v-model="donationModel.gifted_mobile_number"
              :class="{ 'p-invalid': (isSubmitted && donationModel.on_behalf_donation && !donationModel.gifted_mobile_number) ||
                        donationModel.gifted_mobile_number && !isValidMobileNumber(donationModel.gifted_mobile_number) }"
              id="doner-mobile"
              :maxLength="10"
              :phoneNumber="true"
            />
          </div>
        </div>
        <div class="mb-2 color-secondary">وسيلة الدفع</div>
        <div class="payment-methods d-flex align-items-center justify-content-center mb-3">
          <div class="item d-flex align-items-center justify-content-center" @click="cardType = 'credit_card'"
               :class="{'selected': cardType === 'credit_card'}">
            <img 
              class="payment-radio__image payment-radio__image--visa" 
              src="../../assets/images/card-pay-payment.png"
              alt="card-payment" 
              loading="lazy"
              width="100"
              height="42"
            >
          </div>
          <div v-if="browserName === 'safari'">
            <div
                class="apple-pay item d-flex align-items-center justify-content-center"
                @click="applePayDonation"
                :class="{
                  'selected': cardType === 'applepay',
                  'p-disabled': disableSubmit
                }"
            >
              <img
                src="../../assets/images/apple-pay-logo.png"
                alt="apple-pay"
                loading="lazy"
                width="42"
                height="22"
              >
            </div>
          </div>
        </div>
        <div class="row my-5">
          <DonationPrivacyPolicy/>
          <BaseSpinner v-if="showApplepaySpinner"/>
          <Button 
            class="donate__button p-button-rounded p-button p-button-primary mt-2 mx-auto justify-content-center"
            @click.stop="donate"
            v-if="cardType === 'credit_card'"
            :disabled="disableSubmit"
          >
            <i v-if="loading" class="pi pi-spin pi-spinner ms-2"></i>
            <span>تبرع الآن</span>
          </Button>
          <div :class="['form-cont', {'d-none': cardType !== 'applepay'}]"
               v-if="donationModel.donation_amount && isHyperpayInjected">
            <form :action="redirectURI" class="paymentWidgets"
                  :data-brands="'APPLEPAY'"></form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CompletedDonation v-else-if="closedWaqfCondition" />

  <DonationSuccessDialog :showModal="showDonationSuccessModal"
                         :data="successDonationData"
                         :donationType="donationType"
                         v-if="showDonationSuccessModal"
                         @close="showDonationSuccessModal = false"></DonationSuccessDialog>

  <DonationFailureDialog :showModal="showDonationFailureModal"
                         :message="paymentFailureMessage"
                         v-if="showDonationFailureModal"
                         @close="showDonationFailureModal = false"></DonationFailureDialog>
</template>

<script>
import DonationService from "@/services/donation.service";
import DonateCardDetails from "@/components/donate/DonateCardDetails";
import BaseSpinner from "@/components/layout/BaseSpinner";
import CompletedDonation from "@/components/donate/CompletedDonation";
import WaqfService from "@/services/waqf.service";
import DonationFailureDialog from "@/components/donate/DonationFailureDialog";
import DonationSuccessDialog from "@/components/donate/DonationSuccessDialog";
import DonationPrivacyPolicy from '@/components/layout/DonationPrivacyPolicy';
import { DONATION_TYPE } from "@/services/enum.service";
import isValidMobileNumberMixin from "@/mixins/isValidMobileNumberMixin";
import validateOnBehalfDonationMixin from "@/mixins/validateOnBehalfDonationMixin";
import {useHead} from "@vueuse/head";
import { getBrowserEngine } from '@/hooks/useBrowserEnvironmet';

export default {
  name: "donate-waqf",
  mixins: [isValidMobileNumberMixin, validateOnBehalfDonationMixin],
  components: {DonateCardDetails, DonationFailureDialog, DonationSuccessDialog, BaseSpinner, CompletedDonation, DonationPrivacyPolicy },
  data() {
    return {
      waqfId: this.$route.params.waqf_id,
      waqfDetails: null,
      donationModel: {},
      checkoutId: "",
      browserName: "",
      cardType: "credit_card",
      redirectURI: '',
      isSubmitted: false,
      paymentFailureMessage: "",
      showDonationFailureModal: false,
      isHyperpayInjected: false,
      showApplepaySpinner: false,
      resourcePath: "",
      paymentTransactionId: 0,
      showDonationSuccessModal: false,
      successDonationData: {},
      donationType: DONATION_TYPE.CASE,
      disablePaymentFields: false,
      loading: false
    };
  },
  mounted() {
    this.getWaqfInfo();
    this.browserName = getBrowserEngine();
    this.redirectURI = process.env.VUE_APP_FE_URL + `/donate-waqf/${this.waqfId}`;

    if (this.$route.query.reference_id) {
      this.isAmbassador = true;
      this.referenceId = this.$route.query.reference_id;
    }

    if (this.$route.query.id && this.$route.query.resourcePath) {
      this.resourcePath = this.$route.query.resourcePath;
      this.paymentTransactionId = this.$route.query.id;

      this.checkPaymentStatus();
    }
  },
  methods: {
    getWaqfInfo() {
      WaqfService.getWaqfDetails(this.waqfId)
        .then(({ data: { result } }) => {
          this.waqfDetails = result;
          useHead({
            meta: [
              {
                name: "og:description",
                content: this.waqfDetails.description
              },
              {
                name: "og:image",
                content: this.waqfDetails.image_path
              },
              {
                name: 'twitter:image',
                content: this.waqfDetails.image_path
              },
              {
                name: 'twitter:description',
                content: this.waqfDetails.description
              }
            ]
          })
          this.waqfDetails.amountPercentage = Math.floor((this.waqfDetails.balance / this.waqfDetails.target) * 100);
        });
    },
    donate() {

      this.isSubmitted = true;
      this.loading = true
      if(this.donationModel.on_behalf_donation && !this.validateOnBehalfDonation(this.donationModel)) {
        this.loading = false;
        return;
      }

      const postBody = {
        card_type: this.cardType,
        amount: this.donationModel.donation_amount,
        is_waqf_sehi: true,
        object_type: "waqf",
        object_id: this.waqfDetails.id
      };
      DonationService.getPaymentId(postBody)
        .then(({ data: { result: checkout_id } }) => {
          this.checkoutId = checkout_id?.checkout_id;
          localStorage.setItem("donationModel", JSON.stringify(this.donationModel));
          localStorage.setItem("wallet", JSON.stringify(this.waqfDetails.wallet?.id));
          this.injectCheckoutLibrary();
        })
        .catch(({ response: { data: { errors } } }) => {
          this.paymentFailureMessage = errors[0];
          this.showDonationFailureModal = true;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    applePayDonation() {

      this.isSubmitted = true;
      this.disablePaymentFields = true;

      if(!this.donationModel.donation_amount) {
        return;
      }

      this.cardType = 'applepay';
      if (this.isHyperpayInjected) {
        return;
      }

      this.showApplepaySpinner = true;

      const postBody = {
        card_type: this.cardType,
        amount: this.donationModel.donation_amount,
        is_waqf_sehi: true,
        object_type: "waqf",
        object_id: this.waqfDetails.id
      };
      DonationService.getPaymentId(postBody)
        .then(({ data: { result: checkout_id } }) => {
          this.checkoutId = checkout_id?.checkout_id;
          localStorage.setItem("donationModel", JSON.stringify(this.donationModel));
          localStorage.setItem("wallet", JSON.stringify(this.waqfDetails.wallet?.id));
          this.injectCheckoutLibrary();
        })
        .catch(({ response: { data: { errors } } }) => {
          this.paymentFailureMessage = errors[0];
          this.showDonationFailureModal = true;
          this.showApplepaySpinner = false;
        })
        .finally(() => {
          this.loading = false;
        })
    },
    injectCheckoutLibrary() {
      let paymentLibraryScript = document.createElement("script");
      paymentLibraryScript.setAttribute("src", `${process.env.VUE_APP_HYPERPAY_URL}/v1/paymentWidgets.js?checkoutId=${this.checkoutId}`);
      document.head.appendChild(paymentLibraryScript);

      this.isHyperpayInjected = true;
      setTimeout(() => {
        this.showApplepaySpinner = false;
      }, 300)
      if (this.cardType === 'applepay') {
        return;
      }

      this.$router.push(`/checkout?card_type=${this.cardType}`);
    },
    setDonationAmount(amount) {
      if (amount > this.waqfDetails.required_amount - this.waqfDetails.collected_amount) {
        this.donationModel.donation_amount = this.waqfDetails.required_amount - this.waqfDetails.collected_amount;
      }
      else {
        this.donationModel.donation_amount = amount;
      }
    },
    checkPaymentStatus() {
      DonationService.getPaymentStatus(this.paymentTransactionId, this.resourcePath)
        .then(({ data: { response } }) => {

          const donationModel = JSON.parse(localStorage.getItem("donationModel"));
          const postBody = {
            to_wallet: JSON.parse(localStorage.getItem("wallet")),
            amount: donationModel.donation_amount,
            donor_mobile_number: donationModel.doner_mobile,
            checkout_id: this.paymentTransactionId,
            on_behalf_donor_name: donationModel.on_behalf_donor_name ? donationModel.on_behalf_donor_name : null,
            gifted_to: donationModel.gifted_to === "أخرى" ? donationModel.gifted_to_text : donationModel.gifted_to,
            donor_relation: donationModel.donor_relation === "أخرى" ? donationModel.donor_relation_text : donationModel.donor_relation,
            gifted_mobile_number: donationModel.gifted_mobile_number,
            on_behalf_donation: donationModel.on_behalf_donation,
            link_of_the_donated_on_object: this.redirectURI
          };

          DonationService.donate(postBody)
            .then(({ data: { result: { donation_success_data } } }) => {
              this.successDonationData = donation_success_data;
              this.donationType = this.successDonationData.type;
              this.showDonationSuccessModal = true;
            })
            .finally(() => {
              localStorage.removeItem("donationModel");
              localStorage.removeItem("wallet");
            });
        })
        .catch(({ response: { data: { errors } } }) => {
          this.paymentFailureMessage = errors[0];
          this.showDonationFailureModal = true;
        });
    },
  },
  computed: {
    closedWaqfCondition() {
      return this.waqfDetails?.is_completed || this.waqfDetails?.is_cancelled;
    },
    disableSubmit() {
      return !!(!this.donationModel.donation_amount || this.donationModel.doner_mobile && !this.isValidMobileNumber(this.donationModel.doner_mobile) || this.loading)
    },
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/components/donate";
</style>
