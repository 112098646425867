<template>

  <Dialog :visible="showModal" :modal="true" :closable="false" class="p-dialog-share" :showHeader="false"
    :breakpoints="{ '1360px': '50vw', '1024px': '60vw', '960px': '90vw', '640px': '91vw' }" :style="{ width: '30vw' }">
    <div class="body">
      <div class="text-center mb-3 fw-bold mb-4 mt-4 text-dark">
        {{ message }}
      </div>
      <div v-if="message2" class="text-center mb-3 mt-3 text-dark">
        {{ message2 }}
      </div>
      <div class="w-75 m-auto">
        <div class="text-center">
          <button :key="key" id="submit-btn"
            class="p-button p-button-primary btn-lg submit-btn rounded-5 px-5 text-white mx-2" type="button"
            @click.prevent="submit" :disabled="isProcessing" data-cy="home-page">
            <i v-if="isProcessing" class="mx-1">
              <BaseSpinner class="d-inline-block" id="base-spinner" :color="'grey'" :size="1" />
            </i> {{button1Text}}
          </button>
          <button id="close-btn" class="p-button p-button-secondary btn-lg submit-btn rounded-5 px-5 text-white mx-2"
            type="button" @click.prevent="close">
            {{ button2Text }}
          </button>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import BaseSpinner from "@/components/layout/BaseSpinner.vue";
export default {
  name: "ConfirmationDialog",
  components: { BaseSpinner },
  props: {
    showModal: { 
      type: Boolean
    },
    message: {
      type: String
    },
    message2: {
      type: String
    },
    isProcessing: {
      type: Boolean
    },
    button1Text: {
      type: String,
      default: 'تأكيد'
    },
    button2Text: {
      type: String,
      default: 'إغلاق'
    }
  },
  emits: ["close", "submit"],
  data() {
    return {
      key: 0
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      // key is added to force re-render the button and avoid insertionNode err.
      this.key++
      this.$emit("submit");
    }
  },
};
</script>

<style lang="scss" scoped>
.fs-large {
  font-size: 70px;
}
</style>
