import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;

class AmbassadorService {
  static getList({ page, page_size, sort_by, sort_order } : any ) {
    let url = `${baseURL}/ambassador/links/?page=${page}&page_size=${page_size}`;
    if (sort_by && sort_order) {
      url += `&sort_by=${sort_by}&sort_order=${sort_order}`;
    }
    return axios.get(url);
  }

  static postCaseNumber(formData: any) {
    const url = `${baseURL}/ambassador/links/`;
    return axios.post(url, formData);
  }

  static getLink(id: string, case_id:string) {
    const url = `${baseURL}/ambassador/links/${id}/${case_id}`;
    return axios.get(url);
  }

  static getTopAmbassadors() {
    const url = `${baseURL}/ambassador/top-ambassadors/`;
    return axios.get(url);
  }
}

export default AmbassadorService;
