import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive } from "vue"

import { reactive, onMounted } from "vue";
import CardBoard from "@/components/home/CardBoard.vue";
import CaseService from "@/services/case-service";


export default /*@__PURE__*/_defineComponent({
  __name: 'RecommendationCard',
  setup(__props) {

const state = reactive({
    cases: [],
})

const fetchCases = () => {
    CaseService.getRecommendedCard().then((response) => {
        state.cases = response.data.result;
    })
}

onMounted(() => {
    fetchCases();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_KeepAlive, null, [
    (state.cases.length)
      ? (_openBlock(), _createBlock(CardBoard, {
          key: 0,
          type: "cases",
          items: state.cases
        }, null, 8, ["items"]))
      : _createCommentVNode("", true)
  ], 1024))
}
}

})