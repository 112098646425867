export const formatDate = (date: Date) => {
  // Check if the date is already in the format YYYY-MM-DD
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    return date;
  }
  try {
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });
    return `${year}-${month}-${day}`;
  } catch (error) {
    return date;
  }
};

export const formatHijriDate = (date: any) => {
  try {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Pad with zero if needed
    let day = date.getDate().toString().padStart(2, '0'); // Pad with zero if needed
    return `${year}-${month}-${day}`;
  } catch (error) {
    return date;
  }
};

import { getLocalValue,setLocalValue } from "@/hooks/useLocalStorage";

export const formatMobile = (mobile: string) => {
  return mobile ? mobile.replace("-", "") : false;
};

export const tConvert = (time: any) => {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? 'ص' : 'م'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}

export const formatCreationDate = (date: string) => {
  if(!date) {
    return;
  }
  let dateFormated = date.replace('T', ' ').replaceAll('-', '/').split('.')[0];
  const time = dateFormated.split(' ')[1];
  const dateWithNoTime = dateFormated.split(' ')[0]
  const timeConverted = tConvert(time);

  return dateWithNoTime + ' ' + timeConverted;
}

export const toIndianDigits = (num: string) => {
    const id= ['۰','۱','۲','۳','٤','۵','۶','۷','۸','۹'];
    if(!num) {
        return;
    }
    return num.toString().replace(/\d/g, (w) => {
        return id[+w]
    });
}

export const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    );

export const camelToSnake = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const camelToUpper = (str: string) => {
  if(!str) {
    return false;
  } else {
    const words = str.split("_");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(' ')
  }
}

export const checkIfExist = (value: any) => {
  return value ? value.label : '';
}

export const displayGender = (str: string) => {
  if(str === "M") {
    return 'رجال';
  } else if(str === "F") {
    return 'النساء';
  } else {
    return 'Invalid value';
  }
}

export const isValidSaudiID = (saudiID: string, validateIqama: boolean = false) => {
    saudiID = saudiID.trim();

    if (isNaN(parseInt(saudiID))) return false;
    if (saudiID.length !== 10) return false;

    let type = saudiID.substr(0, 1);
    if (validateIqama && type !== '2' || !validateIqama && type !== '1') return false;

    let sum = 0;
    for (let i = 0; i < 10; i++) {
        if (i % 2 === 0) {
            let ZFOdd = String('00' + String(Number(saudiID.substr(i, 1)) * 2)).slice(-2);
            sum += Number(ZFOdd.substr(0, 1)) + Number(ZFOdd.substr(1, 1));
        } else {
            sum += Number(saudiID.substr(i, 1));
        }
    }
    return (sum % 10 === 0);
}

export const isValidMobileNumber = (mobileNumber: string) => {
  if (!mobileNumber) {
    return false;
  }
  return /^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/.test(mobileNumber);
};

export const debounce = (func: (...args: any[]) => void, wait = 1000) => {
  let timeout: ReturnType<typeof setTimeout>;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    if (typeof file === 'string' && file.startsWith('data:')) {
      resolve(file);
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    }
});

export const isHttpUrl = (url : any) => { 
  const getUrl = url?.name ? url.name : url;
  return getUrl?.startsWith('http') || getUrl.startsWith('https');
}

export const scrollIntoView = (identifier:string) => {
  const element = document.querySelector(identifier)
  return element ? element.scrollIntoView({ behavior: 'smooth', block: 'center' }) : null
}

export const scrollNextErrorIntoView = (elId = ".p-invalid, .file-input-invalid") => {
  return scrollIntoView(elId)
}

export const addStepToCharity = (stepKey:any, newValue:any) => {
  // Get the existing charity_request object from local storage
  let charityRequest = getLocalValue('charity_request');
  // Add the new step data to the charity_request object
  charityRequest[stepKey] = newValue;
  // Save the updated charity_request object back to local storage
  setLocalValue('charity_request', charityRequest);
};