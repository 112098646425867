export const getLocalValue = (key: any): any => {
    const storedValue = localStorage.getItem(key);
    let parsedValue: any = null;

    // Check if the stored value is valid JSON
    try {
        parsedValue = storedValue ? JSON.parse(storedValue) : null;
    } catch (error) {
        parsedValue = storedValue;
    }

    return parsedValue;
};

export const setLocalValue = (key: any, newValue: any): void => {
    localStorage.setItem(key, JSON.stringify(newValue));
};

export const removeLocalValue = (key: any): void => {
    localStorage.removeItem(key);
};