import axios from "axios";

const baseURL = process.env.VUE_APP_API_URL;

class MediaService {
  static async downloadFile(filePath: string) {
    const url = `${baseURL}/charity/file/download/?file_path=${filePath}`;
    let filePathArr = filePath.split('/');
    let fileName = filePathArr[filePathArr.length - 1];

    const response = await axios.get(url, {responseType: 'blob'});
    let fileURL = window.URL.createObjectURL(new Blob([response.data]));
    let fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
  }
  static async getSmsPreview() {
    const response = await axios.get(`${baseURL}/sms-preview-image`);
    return response;
  }
}

export default MediaService;
