import { STORE_ENUM } from "@/store/store.enums";
import AdminPanelService from "@/services/admin-panel.service";

export default {
  state: {
    adminMenuItems: [],
    caseStatuses: [],
    charityRequests: [],
    userRole:''
  },
  getters: {},
  actions: {
    [STORE_ENUM.MENU_ITEMS]({ commit }: any) {
      return new Promise((resolve, reject) => {
        AdminPanelService.getUserMenuItems()
          .then(({ data: { result, admin_name, active_role, user_has_multiple_roles } }) => {
            commit(STORE_ENUM.MENU_ITEMS, {result, active_role});

            resolve({ admin_name, active_role, user_has_multiple_roles });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [STORE_ENUM.CASE_STATUSES]({ commit }: any) {
      return new Promise((resolve, reject) => {
        AdminPanelService.getAdminMenu()
          .then(({ data: { result } }) => {
            commit(STORE_ENUM.CASE_STATUSES, result);

            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    [STORE_ENUM.CHARITY_REQUESTS]({ commit }: any) {
      return new Promise((resolve, reject) => {
        AdminPanelService.getCharityRequestsSubMenu()
          .then(({ data: { result } }) => {
            commit(STORE_ENUM.CHARITY_REQUESTS, result);

            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    [STORE_ENUM.MENU_ITEMS](state: any, {result, active_role}: any) {
      state.adminMenuItems = result;
      state.userRole = active_role;
    },
    [STORE_ENUM.CASE_STATUSES](state: any, result: any) {
      state.caseStatuses = result;
      // @ts-ignore
      state.adminMenuItems.find(item => item.id === 1)?.sub_items = state.caseStatuses;
    },
    [STORE_ENUM.CHARITY_REQUESTS](state: any, result: any) {
      state.charityRequests = result;
      // @ts-ignore
      state.adminMenuItems.find(item => item.id === 100)?.sub_items = state.charityRequests;
    },

    CHANGE_COUNT(state: any, payload: { status: string, payment_request_check: boolean }) {
      const { status, payment_request_check = false} = payload;
      // if payment_request_check = true then menuItems does not include subitems
      const menuItems = payment_request_check ? state.adminMenuItems : state.adminMenuItems[0]?.sub_items
      // if the data is not coming from payment request then change data in subitems of menuItems
      if (!payment_request_check){
          const index = menuItems?.findIndex((e: any) => e.name === status)
          if (index != -1  && index >=0 && menuItems[index]?.count) {
            state.adminMenuItems[0].sub_items[index].count = menuItems[index].count - 1
          }
      }
      // if the data is coming from payment request then change menu items only
      else {
          const index = menuItems.findIndex((e: any) => e.name === status)
          if (index != -1  && index >=0 && menuItems[index]?.counter) {
            state.adminMenuItems[index].counter = menuItems[index].counter - 1
          }
      }
    }
  },
}
