import { toValue } from "vue";
import { computed } from "vue";

export function useGenerateShareLinks(rawLink) {

  //@TODO: for future reference: check if browser is mobile (useIsBrowserEnvironment()) and open in app if true
  const facebookLink = computed(() => makeFacebookLink(toValue(rawLink)))
  const twitterLink = computed(() => makeTwitterLink(toValue(rawLink)))
  const whatsappLink = computed(() => makeWhatsappLink(toValue(rawLink)))

  const makeFacebookLink = (link) => {
    return `https://www.facebook.com/sharer.php?u=${link}`;
  }
  const makeTwitterLink = (link) => {
    return `https://twitter.com/share?url=${link}`;
  }
  const makeWhatsappLink = (link) => {
    return `https://wa.me/?text=${link}`;
  }

  return {
    facebookLink,
    twitterLink,
    whatsappLink,
    makeFacebookLink,
    makeTwitterLink,
    makeWhatsappLink,
  };
}
