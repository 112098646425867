<template>
    <Dialog
        :visible="showModal"
        :modal="true"
        :closable="false"
        class="sms-preview-modal p-dialog-share px-3"
    >
        <template #header>
            <h3>معاينة رسالة التبرع</h3>
        </template>
        <div class="text-center">
            <img :src="previewImageLink" alt="SMS Preview" class="responsive-image"/>
        </div>
        <div class="text-center mt-4">
            <button
                id="close-btn"
                class="btn btn-primary btn-lg rounded-5 px-5 text-white"
                type="button"
                @click.prevent="close"
            >
                إغلاق
            </button>
        </div>
    </Dialog>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

const props = defineProps({
  showModal: Boolean,
  previewImageLink: String
});

const emit = defineEmits(['close']);

function close() {
  emit('close');
}
</script>

<style scoped>
.responsive-image {
    max-width: 100%;
    max-height: 60vh;
    object-fit: contain;
}
</style>