import axios from "axios";

const baseURL = process.env.VUE_APP_API_URL;

class CharityService {
  static getCharityList({ page, page_size, type }: any) {
    let url = `${baseURL}/charity/cards?page=${page}&page_size=${page_size}`;
    url += type && type !== "" ? `&${type}=1` : "";

    return axios.get(url);
  }
  static getCharityRequestDetail(id: any) {
    let url = `${baseURL}/charity/request/${id}/`;

    return axios.get(url);
  }

  static patchCharityRequestDetail(id: any, data: any) {
    let url = `${baseURL}/charity/request/${id}/`;

    return axios.patch(url, data);
  }
  static getNearestCharities(requestBody: any) {
    let url = `${baseURL}/charity/nearby-charities`;

    return axios.post(url,requestBody);
  }
}

export default CharityService;