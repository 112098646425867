<template>
    <keep-alive>
        <CardBoard v-if="state.cases.length" type="cases" :items="state.cases" />
    </keep-alive>
</template>

<script setup lang="ts">
import { reactive, onMounted } from "vue";
import CardBoard from "@/components/home/CardBoard.vue";
import CaseService from "@/services/case-service";

const state = reactive({
    cases: [],
})

const fetchCases = () => {
    CaseService.getRecommendedCard().then((response) => {
        state.cases = response.data.result;
    })
}

onMounted(() => {
    fetchCases();
});
</script>